import React from 'react';

import home_insurance from '../images/home_insurance.svg';
import life_insurance from '../images/life_insurance.svg';
import * as headerStyles from '../styles/common.module.css';

const HPHomeInsurance = () => {
  return (
    <>
      <div className={headerStyles.insMainContainer + ' col-sm-12'}>
        <div className={headerStyles.insSubContainer + ' col-sm-6'}>
          <div className={headerStyles.insTable}>
            <div className={headerStyles.insImageMainContainer}>
              <div className={headerStyles.insImageSubContainer}>
                <img src={home_insurance} className={headerStyles.insImgHeight} alt="Bubble Insurance"></img>
              </div>

              <p className={headerStyles.insTdHomeInsuranceText}><b>Home Insurance</b></p>
            </div>
          </div>

          <div className={headerStyles.padding20}>
            <p className={headerStyles.insParagraph}>Extended coverage against damage and unforseen circumstances.</p>

            <p className={headerStyles.insParagraph}>Data driven insight that will go to two lines hopefully.</p>

            <p className={headerStyles.insParagraph}>One line about the partner, Stillwater will go here.</p>

            <p className={headerStyles.insParagraph}>Get it standalone, or part of your Bubble.</p>
          </div>

          <div className={headerStyles.padding20}>
            <div className={headerStyles.insBttn}>
              <button type="button" className={headerStyles.insBttnStyle}>Learn More</button>
            </div>

            <div className={headerStyles.insHyperText}>
              <div className={headerStyles.insHyperTextStyle}>
                <a href="">Already have Home Insurance ?</a>
              </div>
            </div>
          </div>
        </div>

        <div className={headerStyles.insSubContainer + ' col-sm-6'}>
          <div className={headerStyles.insTable}>
            <div className={headerStyles.insImageMainContainer}>
              <div className={headerStyles.insImageSubContainer}>
                <img src={life_insurance} className={headerStyles.insImgHeight} alt="Bubble Life Insurance"></img>
              </div>

              <p className={headerStyles.insTdHomeInsuranceText}><b>Life Insurance</b></p>
            </div>
          </div>

          <div className={headerStyles.padding20}>
            <p className={headerStyles.insParagraph}>Personalised, flexible coverage that can adapt with your life's changing needs.</p>

            <p className={headerStyles.insParagraph}>Shorter application process based on data driven insights.</p>

            <p className={headerStyles.insParagraph}>Partner Related insight for PacLife, SBLI and Principal.</p>

            <p className={headerStyles.insParagraph}>Get it standlone, or pat of your Bubble.</p>
          </div>

          <div className={headerStyles.padding20}>
            <div className={headerStyles.insBttn}>
              <button type="button" className={headerStyles.insBttnStyle}>Learn More</button>
            </div>

            <div className={headerStyles.insHyperText}>
              <div className={headerStyles.insHyperTextStyle}>
                <a href="">Already have Life Insurance ?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HPHomeInsurance;
